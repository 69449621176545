import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BaseMatter,
  DetailedMatter,
} from '../../services/types/client-intake-types';

interface MatterState {
  matters: BaseMatter[];
  selectedMatter: string | null;
  selectedMatterData?: DetailedMatter;
}

const initialState: MatterState = {
  matters: [],
  selectedMatter: null,
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setMatters(state, action: PayloadAction<BaseMatter[]>) {
      state.matters = action.payload;
    },
    setSelectedMatter(state, action: PayloadAction<string>) {
      state.selectedMatter = action.payload;
    },
    setSelectedMatterData(state, action: PayloadAction<DetailedMatter>) {
      state.selectedMatterData = action.payload;
    },
  },
});

export const { setMatters, setSelectedMatter, setSelectedMatterData } =
  clientsSlice.actions;

export default clientsSlice.reducer;
