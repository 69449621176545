import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { Box, IconButton, Link, Stack, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import WavesurferPlayer from '@wavesurfer/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import WaveSurfer from 'wavesurfer.js';
import { audioService } from '../../services/api/audioService';
import { selectSelectedMatterData } from '../../state/selectors/matter-selectors';
import { formatCallDuration, formatIntakeCall } from '../../utils/formatting';
import ClientScreenTile from '../base/ClientScreenTile';

const IntakeCallSection: React.FC = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const matterData = useSelector(selectSelectedMatterData);
  const audioPath = matterData?.conversations[0]?.audio_path;
  const conversationId = matterData?.conversations[0]?.id;
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);

  const onReady = (ws: WaveSurfer) => {
    setWavesurfer(ws);
    setIsPlaying(false);
    setTotalDuration(ws.getDuration());
  };

  const onPlayPause = () => wavesurfer && wavesurfer.playPause();

  useEffect(() => {
    const fetchAudioUrl = async () => {
      if (!audioPath) {
        Sentry.captureException(`No audio path provided for ${conversationId}`);

        return;
      }

      const responseUrl = await audioService.fetchPresignedUrl(audioPath);
      if (responseUrl) {
        setAudioUrl(responseUrl);
      }
    };

    fetchAudioUrl();
  }, [audioPath, conversationId]);

  useEffect(() => {
    const fetchPdf = async () => {
      if (conversationId) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_DJANGO_BACKEND_HOST}/api/transcript/${conversationId}/pdf/`,
            {
              credentials: 'include',
            },
          );

          if (!response.ok) {
            throw new Error('Failed to fetch the PDF');
          }

          const blob = await response.blob();
          setPdfBlob(blob);
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    };

    fetchPdf();
  }, [conversationId]);

  useEffect(() => {
    if (!wavesurfer) return;

    const onTimeUpdate = (time: number) => {
      setCurrentTime(time);
    };

    wavesurfer.on('audioprocess', onTimeUpdate);

    // eslint-disable-next-line consistent-return
    return () => {
      wavesurfer.un('audioprocess', onTimeUpdate);
    };
  }, [wavesurfer]);

  return (
    <ClientScreenTile>
      <Stack
        direction="row"
        sx={{ display: 'flex', alignItems: 'flex-start' }}
        justifyContent="space-between"
      >
        <Stack direction="column" spacing={2}>
          <Typography variant="h3">Intake Call</Typography>
          {matterData && (
            <Typography variant="subtitle1">
              {formatIntakeCall(matterData)}
            </Typography>
          )}
        </Stack>
        <Stack direction="row" gap="24px" alignItems="center">
          {pdfBlob && (
            // TODO make shared button component
            <Link
              variant="body1"
              color="#3E21F3"
              href={URL.createObjectURL(pdfBlob)}
              component="a"
              target="_blank"
              rel="noreferrer"
              sx={{ letterSpacing: '0px' }}
            >
              View Transcript
            </Link>
          )}
          {audioUrl && (
            <Link
              variant="body1"
              color="#3E21F3"
              href={audioUrl}
              download
              component="a"
              target="_blank"
              rel="noreferrer"
              sx={{ letterSpacing: '0px' }}
            >
              Download MP3
            </Link>
          )}
        </Stack>
      </Stack>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: '24px' }}>
        <IconButton
          onClick={onPlayPause}
          disableRipple
          sx={{
            svg: {
              fill: '#000000',
            },
          }}
        >
          {isPlaying ? (
            <PauseCircleFilledIcon fontSize="large" />
          ) : (
            <PlayCircleFilledWhiteIcon fontSize="large" />
          )}
        </IconButton>
        <WavesurferPlayer
          height={50}
          width={700}
          waveColor="#000000"
          progressColor="#D9D9D9"
          cursorColor="#000000"
          cursorWidth={2}
          barHeight={1}
          url={audioUrl || ''}
          hideScrollbar
          barWidth={4}
          barGap={3}
          barRadius={10}
          onReady={onReady}
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        />
        <Box
          sx={{
            minWidth: 50,
            textAlign: 'right',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: '700',
            color: '#808080',
          }}
        >
          {formatCallDuration(
            Math.floor(currentTime),
            Math.floor(totalDuration),
          )}
        </Box>
      </Box>
    </ClientScreenTile>
  );
};

export default IntakeCallSection;
