import styled from '@emotion/styled';
import { Button } from '@mui/material';

const SecondaryButton = styled(Button)({
  lineHeight: '24px',
  textDecoration: 'none',
  textTransform: 'none',
  backgroundColor: '#3E21F3',
  height: '54px',
  fontSize: '16px',
  color: 'white',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: 'grey',
  },
});

const TealIconButton = styled(Button)({
  svg: {
    fill: '#03C5A2',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export { SecondaryButton, TealIconButton };
