import { Box, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedMatterData } from '../../state/selectors/matter-selectors';

// NOTE! This is a VERY temp component for use for Viraj at a conference. Actual mobile work will be done
// differently.
const MobileKeyConsiderations: React.FC = () => {
  const matterData = useSelector(selectSelectedMatterData);

  return (
    <>
      {matterData?.key_considerations &&
        matterData?.key_considerations.length > 0 && (
          <Paper sx={{ padding: '16px', gap: '32px' }}>
            <Typography variant="h3" align="left" sx={{ mb: '16px' }}>
              Key Case Considerations
            </Typography>

            <Stack spacing={3}>
              {matterData?.key_considerations &&
                matterData.key_considerations.map((consideration) => (
                  <Box
                    key={consideration.id}
                    sx={{ padding: '8px', borderBottom: '1px solid #ddd' }}
                  >
                    <Typography
                      variant="h2"
                      sx={{ mb: '12px', fontWeight: 'bold' }}
                    >
                      {consideration.category}:
                      <Box
                        component="span"
                        sx={{
                          fontWeight: 'normal',
                          ml: '4px',
                        }}
                      >
                        {consideration.severity}
                      </Box>
                    </Typography>
                    <Typography variant="body2">
                      {consideration.content}
                    </Typography>
                  </Box>
                ))}
            </Stack>
          </Paper>
        )}{' '}
    </>
  );
};

export default MobileKeyConsiderations;
