import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

type ClientScreenTileProps = {
  children: ReactNode;
};

const ClientScreenTile: React.FC<ClientScreenTileProps> = ({ children }) => {
  return (
    <Box
      sx={{
        padding: '24px',
        backgroundColor: 'white',
        border: '1px solid #F0F0F0',
        borderRadius: '4px',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.05)',
        boxSizing: 'border-box',
      }}
    >
      {children}
    </Box>
  );
};

export default ClientScreenTile;
