import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetMatterDetailsQuery,
  useGetMattersForUserQuery,
} from '../services/api';
import {
  setMatters,
  setSelectedMatter,
  setSelectedMatterData,
} from '../state/reducers/matter';
import { selectSelectedMatter } from '../state/selectors/matter-selectors';

const useMatterSelection = () => {
  const dispatch = useDispatch();
  const selectedMatter = useSelector(selectSelectedMatter);

  const {
    data: allMatters,
    error: allMattersError,
    isLoading: allMattersLoading,
    refetch: refetchMatters,
  } = useGetMattersForUserQuery();

  const {
    data: detailedMatter,
    error: detailedMatterError,
    isLoading: detailedMatterLoading,
    refetch,
  } = useGetMatterDetailsQuery(selectedMatter!, {
    skip: !selectedMatter,
  });

  // Set matters for the sidebar
  useEffect(() => {
    if (allMatters) {
      dispatch(setMatters(allMatters));
    }
    if (!allMatters || allMatters.length === 0) {
      refetchMatters();
    }
  }, [allMatters, dispatch, refetchMatters]);

  // Set the first one to be selected by default
  useEffect(() => {
    if (!allMattersLoading && allMatters && allMatters.length > 0) {
      dispatch(setSelectedMatter(allMatters[0].id));
    }
  }, [dispatch, allMattersLoading, allMatters]);

  // Set the detailed matter data for the selected matter
  useEffect(() => {
    if (!detailedMatterLoading && detailedMatter) {
      dispatch(setSelectedMatterData(detailedMatter));
    }
  }, [
    detailedMatter,
    detailedMatterLoading,
    selectedMatter,
    dispatch,
    refetch,
  ]);

  useEffect(() => {
    if (!allMatters || allMatters.length === 0) {
      const interval = setInterval(() => {
        refetchMatters();
      }, 3000);

      return () => clearInterval(interval); // Cleanup the interval on component unmount
    }

    return undefined; // Explicitly return undefined if no interval is set
  }, [allMatters, refetchMatters]);

  const handleMatterChange = (id: string) => {
    dispatch(setSelectedMatter(id));
  };

  return {
    allMatters,
    allMattersError,
    detailedMatterError,
    selectedMatter,
    handleMatterChange,
  };
};

export default useMatterSelection;
