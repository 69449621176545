import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedMatterData } from '../state/selectors/matter-selectors';
import ClientScreenTile from './base/ClientScreenTile';
import { MatterSummary } from '../services/types/client-intake-types';

const SummaryItem: React.FC<{ title: string; children: React.ReactNode }> = ({
  title,
  children,
}) => {
  return (
    <Grid item xs={12} sm={4} key={title}>
      <Typography variant="body1" sx={{ mb: '8px' }}>
        {title}
      </Typography>

      {children}
    </Grid>
  );
};

const Summary: React.FC<{ summary: MatterSummary }> = ({ summary }) => {
  return (
    <SummaryItem title={summary.category}>
      <Typography variant="subtitle1">{summary.content}</Typography>
    </SummaryItem>
  );
};

const ActionItems: React.FC<{ actionItems: string[] }> = ({ actionItems }) => {
  return (
    <SummaryItem title="Action Items">
      <List
        sx={{ listStyleType: 'disc', listStylePosition: 'inside', padding: 0 }}
      >
        {actionItems.length === 0 ? (
          <Typography variant="subtitle1">No action items</Typography>
        ) : (
          actionItems.map((item) => (
            <ListItem
              key={item}
              sx={{ padding: '8px', paddingTop: '0px', paddingY: 0 }}
            >
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="subtitle1" sx={{ display: 'list-item' }}>
                    {item}
                  </Typography>
                }
              />
            </ListItem>
          ))
        )}
      </List>
    </SummaryItem>
  );
};

const Summaries: React.FC = () => {
  const matterData = useSelector(selectSelectedMatterData);

  return (
    <ClientScreenTile>
      <Stack direction="column" spacing={2}>
        <Typography variant="h3">Case Summary</Typography>
        <Grid container spacing={3} sx={{ maxWidth: '100%' }}>
          {matterData?.summaries &&
            matterData.summaries.map((summary) => (
              <Summary key={summary.id} summary={summary} />
            ))}
          <ActionItems
            actionItems={
              matterData?.action_items.map((item) => item.content) || []
            }
          />
        </Grid>
      </Stack>
    </ClientScreenTile>
  );
};

export default Summaries;
