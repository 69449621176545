import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCheckSessionQuery } from '../../services/api/userService';
import {
  setCsrfToken,
  setEmail,
  setLoggedIn,
  setSessionId,
} from '../../state/reducers/root';

const SessionManager: React.FC = () => {
  const dispatch = useDispatch();
  const { data, error } = useCheckSessionQuery();

  useEffect(() => {
    const sessionData = JSON.parse(localStorage.getItem('sessionData') || '{}');

    if (sessionData && data?.is_valid) {
      dispatch(setEmail(data.email));
      dispatch(setLoggedIn(true));
      dispatch(setCsrfToken(sessionData.csrfToken));
      dispatch(setSessionId(sessionData.sessionId));
    } else if (error) {
      localStorage.removeItem('sessionData');
      dispatch(setEmail(null));
      dispatch(setLoggedIn(false));
      dispatch(setCsrfToken(null));
      dispatch(setSessionId(null));
    }
  }, [dispatch, data, error]);

  return null;
};

export default SessionManager;
