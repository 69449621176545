import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithErrorHandling } from './baseQuery';

// TODO joanie switch back to these from fetch APIs
export const documentApiClient = createApi({
  reducerPath: 'documentApi',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['TranscriptPdf', 'EngagementPdf', 'FormPdf'] as const,
  endpoints: (builder) => ({
    getTranscriptPdf: builder.query<any, string>({
      query: (conversationId) => `api/transcript/${conversationId}/pdf/`,
      providesTags: (result, error, conversationId) => [
        { type: 'TranscriptPdf', id: conversationId },
      ],
      transformResponse: (response: Blob) => {
        return response;
      },
    }),
    getEngagementPdf: builder.query<any, string>({
      query: (conversationId) => `api/documents/engagement/${conversationId}`,
      providesTags: (result, error, conversationId) => [
        { type: 'EngagementPdf', id: conversationId },
      ],
      transformResponse: (response: Blob) => {
        return response;
      },
    }),
    getFormPdf: builder.query<any, string>({
      query: (formId) => `api/documents/form/${formId}`,
      providesTags: (result, error, formId) => [
        { type: 'FormPdf', id: formId },
      ],
      transformResponse: (response: Blob) => {
        return response;
      },
    }),
  }),
});

export const {
  useGetTranscriptPdfQuery,
  useGetEngagementPdfQuery,
  useGetFormPdfQuery,
} = documentApiClient;
