import { Stack, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedMatterData } from '../state/selectors/matter-selectors';
import { SecondaryButton } from './base/Buttons';

const MatterTitle: React.FC = () => {
  const matterData = useSelector(selectSelectedMatterData);
  const audioConversation = matterData?.conversations[0];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [medicalRecordButtonText, setMedicalRecordButtonText] =
    useState<string>('Request Medical Records');
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);

  useEffect(() => {
    const fetchEngagementPdf = async () => {
      if (audioConversation?.id) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_DJANGO_BACKEND_HOST}/api/documents/engagement/${audioConversation.id}`,
            {
              credentials: 'include',
            },
          );

          if (!response.ok) {
            throw new Error('Failed to fetch the engagement PDF');
          }

          const blob = await response.blob();
          setPdfBlob(blob);
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    };

    fetchEngagementPdf();
  }, [audioConversation?.id]);

  const handleDownload = () => {
    if (pdfBlob) {
      const url = URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${matterData?.name}-engagement-letter.pdf`;
      link.click();
      URL.revokeObjectURL(url);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleRequestMedicalRecords = () => {
    Sentry.captureMessage(`Medical record request: ${matterData?.id}`);
    setMedicalRecordButtonText('Medical Records Requested');
  };

  return (
    <div>
      <Stack
        direction="row"
        gap="20px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="column">
          <Typography variant="h1">{matterData?.name}</Typography>
          <Typography
            sx={{ fontWeight: 500, fontSize: '1rem', color: '#404040' }}
            variant="body2"
          >
            {matterData?.type}
          </Typography>
        </Stack>
        <Stack direction="row" gap="8px">
          <SecondaryButton disableRipple onClick={handleDownload}>
            Download Engagement Letter
          </SecondaryButton>
          {/* <SecondaryButton disableRipple onClick={handleRequestMedicalRecords}>
            {medicalRecordButtonText}
          </SecondaryButton> */}
        </Stack>
      </Stack>
    </div>
  );
};

export default MatterTitle;
