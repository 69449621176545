import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Box,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import useMatterSelection from '../hooks/useMatterSelection';
import FinchLogo from './base/FinchLogo';
import LoggedInUser from './LoggedInUser';
import { ActiveCall } from './ActiveCall';

const Sidebar: React.FC = () => {
  const {
    allMatters,
    allMattersError,
    detailedMatterError,
    selectedMatter,
    handleMatterChange,
  } = useMatterSelection();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fff',
        borderRight: 1,
        borderColor: 'white',
        padding: '24px',
        height: 'calc(100% - 48px)',
      }}
    >
      <Box sx={{ padding: 2 }}>
        <FinchLogo />
      </Box>
      <Box
        sx={{
          borderBottom: '1px solid #F0F0F0',
          paddingBottom: '8px',
          mt: '40px',
        }}
      >
        <Typography variant="h2">Clients</Typography>
      </Box>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {allMattersError || detailedMatterError || allMatters?.length === 0 ? (
          <Typography variant="body1" mt="16px">
            Your client calls will appear here.
          </Typography>
        ) : (
          <List>
            {allMatters &&
              allMatters.map((matter) => (
                <ListItemButton
                  disableRipple
                  key={matter.id}
                  sx={{
                    paddingY: '16px',
                    width: '312px',
                    height: '78px',
                    '&.Mui-selected': {
                      backgroundColor: '#F2F2F2',
                      borderRadius: '8px',
                    },
                  }}
                  onClick={() => handleMatterChange(matter.id)}
                  selected={selectedMatter === matter.id}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body1">{matter.name}</Typography>
                    }
                    secondary={
                      <Typography
                        variant="body2"
                        sx={{
                          color: '#7a7a7a',
                        }}
                      >
                        {matter.status}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <ArrowForwardIcon sx={{ color: '#7a7a7a' }} />
                  </ListItemSecondaryAction>
                </ListItemButton>
              ))}
          </List>
        )}
      </Box>
      <ActiveCall />
      <LoggedInUser />
    </Box>
  );
};

export default Sidebar;
