import {
  Box,
  Grid,
  Input,
  Link,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useGetMatterDetailsQuery,
  useUpdateFormFieldMutation,
} from '../../services/api/mattersService';
import {
  selectSelectedMatter,
  selectSelectedMatterData,
} from '../../state/selectors/matter-selectors';
import theme from '../../theme/theme';

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const IntakeForm: React.FC = () => {
  const selectedMatterData = useSelector(selectSelectedMatterData);
  const intakeForm = selectedMatterData?.form;
  const [value, setValue] = useState(0);
  const [formBlob, setFormBlob] = useState<Blob | null>(null);
  const [key, setKey] = React.useState(0);
  const selectedMatterId = useSelector(selectSelectedMatter);

  const [updateFormField] = useUpdateFormFieldMutation();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { refetch } = useGetMatterDetailsQuery(selectedMatterId!, {
    skip: !selectedMatterId,
  });

  useEffect(() => {
    setValue(0);
    refetch();
    setKey((prevKey) => prevKey + 1);
  }, [refetch, selectedMatterId]);

  useEffect(() => {
    setValue(0);
    setKey((prevKey) => prevKey + 1);
  }, []);

  useEffect(() => {
    const fetchPdf = async () => {
      if (selectedMatterData?.form?.id) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_DJANGO_BACKEND_HOST}/api/documents/form/${selectedMatterData.form.id}`,
            {
              credentials: 'include',
            },
          );

          if (!response.ok) {
            throw new Error('Failed to fetch the PDF');
          }

          const blob = await response.blob();
          setFormBlob(blob);
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    };

    fetchPdf();
  }, [selectedMatterData?.form?.id]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleFormFieldChange = async (
    matterId: string,
    formFieldId: string,
    newValue: string,
  ) => {
    try {
      const patch = {
        answer_data: newValue,
      };
      await updateFormField({
        matter_id: matterId,
        id: formFieldId,
        patch,
      }).unwrap();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  if (!intakeForm) {
    return null;
  }

  return (
    <Paper sx={{ padding: '24px' }} key={key}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h3">Intake Form</Typography>
        {formBlob && (
          <Link
            variant="body1"
            color="#3E21F3"
            href={URL.createObjectURL(formBlob)}
            download={`${selectedMatterData.name}_form.pdf`}
            component="a"
            target="_blank"
            rel="noreferrer"
            sx={{ letterSpacing: '0px' }}
          >
            Download Form
          </Link>
        )}
      </Stack>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="intake form tabs"
          textColor="secondary"
          indicatorColor="secondary"
        >
          {[...intakeForm.form_pages]
            ?.sort((a, b) => {
              if (a.order === null) return 1; // Place null values at the end
              if (b.order === null) return -1;
              return a.order - b.order;
            })
            .map((page, index) => (
              <Tab
                disableRipple
                key={index}
                label={page.title}
                id={`tab-${index}`}
                sx={{
                  fontFamily: 'Inter',
                  textTransform: 'none',
                  '&.Mui-selected': {
                    color: 'info.main',
                    borderColor: 'info.main',
                  },
                }}
              />
            ))}
        </Tabs>
      </Box>

      {intakeForm.form_pages?.map((page, pageIndex) => (
        <TabPanel value={value} index={pageIndex} key={pageIndex}>
          <Grid container spacing={2} columns={{ xs: 3, sm: 8, md: 12 }}>
            {[...page.form_fields]
              .sort((a, b) => a.order - b.order)
              .map((field, itemIndex) => (
                <Grid
                  item
                  xs={2}
                  sm={4}
                  md={4}
                  key={itemIndex}
                  sx={{ mt: '32px', p: '8px' }}
                >
                  <Stack direction="column" spacing="4px">
                    <Typography variant="h2" sx={{ fontSize: '12px' }}>
                      {field.title}
                    </Typography>
                    <Input
                      multiline
                      defaultValue={field.answer_data}
                      onBlur={(e) => {
                        handleFormFieldChange(
                          selectedMatterData?.id,
                          field.id,
                          e.target.value,
                        );
                      }}
                      sx={{
                        ...theme.typography.body1,
                        ml: '16px',
                        fontWeight: '500',
                        '&.MuiInput-root::before': {
                          border: 'none',
                        },
                      }}
                    />
                  </Stack>
                </Grid>
              ))}
          </Grid>
        </TabPanel>
      ))}
    </Paper>
  );
};

export default IntakeForm;
