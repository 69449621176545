import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectLoggedIn } from '../../state/selectors/root-selectors';

const PrivateRoute: React.FC<{ element: React.ReactElement }> = ({
  element,
}) => {
  const loggedIn = useSelector(selectLoggedIn);

  return loggedIn ? element : <Navigate to="/login" replace />;
};

export default PrivateRoute;
