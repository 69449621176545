import { createApi } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { BaseMatter, DetailedMatter } from '../types/client-intake-types';
import { FormField } from '../types/form-types';
import { baseQueryWithErrorHandling } from './baseQuery';

export const mattersApiClient = createApi({
  reducerPath: 'matterApi',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['MatterDetails'] as const,
  endpoints: (builder) => ({
    getMatterDetails: builder.query<DetailedMatter, string>({
      query: (matterId) => `api/matters/case_details/${matterId}/`,
      transformResponse: (response: DetailedMatter): DetailedMatter => ({
        id: response.id,
        name: response.name,
        type: response.type,
        status: response.status,
        creator: response.creator,
        firm: response.firm,
        engagements: response.engagements,
        clients: response.clients,
        conversations: response.conversations,
        action_items: response.action_items,
        key_considerations: response.key_considerations,
        summaries: response.summaries,
        form: response.form,
      }),
      providesTags: (result, error, matterId) => [
        { type: 'MatterDetails', id: matterId },
      ],
    }),
    getMattersForUser: builder.query<BaseMatter[], void>({
      query: () => `api/matters/firm_user/`,
    }),
    updateFormField: builder.mutation<
      FormField,
      { matter_id: string; id: string; patch: Partial<FormField> }
    >({
      query: ({ matter_id, id, patch }) => ({
        url: `api/matters/${matter_id}/formfields/${id}/`,
        method: 'PATCH',
        body: patch,
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
      }),
      invalidatesTags: (result, error, { matter_id }) => [
        { type: 'MatterDetails', id: matter_id },
      ],
    }),
  }),
});

export const {
  useGetMatterDetailsQuery,
  useGetMattersForUserQuery,
  useUpdateFormFieldMutation,
} = mattersApiClient;
