import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import useMatterSelection from '../hooks/useMatterSelection';
import { selectSelectedMatterData } from '../state/selectors/matter-selectors';

const MatterTopBar: React.FC = () => {
  const { allMatters, selectedMatter, handleMatterChange } =
    useMatterSelection();
  const matterData = useSelector(selectSelectedMatterData);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #F0F0F0',
      }}
    >
      <Stack direction="column" gap="24px" mb="24px">
        <InputLabel id="select-matter">
          <Typography variant="h1" color="black">
            Select a conversation
          </Typography>
        </InputLabel>

        <Select
          labelId="select-matter"
          value={selectedMatter || ''}
          // variant="standard"
          onChange={(e) => handleMatterChange(e.target.value as string)}
          displayEmpty
          sx={{ width: 'calc(100vw - 48px)', padding: '16px', height: '64px' }}
          renderValue={() =>
            matterData ? (
              <Box m="8px">
                <Typography variant="h1" sx={{ fontSize: '1.25rem' }}>
                  {matterData.name}
                </Typography>
                <Stack direction="row" gap="20px" alignItems="center">
                  <Typography
                    sx={{ fontWeight: 500, fontSize: '1rem', color: '#404040' }}
                    variant="body2"
                  >
                    {matterData.type}
                  </Typography>
                </Stack>
              </Box>
            ) : (
              'Select Matter'
            )
          }
        >
          {allMatters &&
            allMatters.map((matter) => (
              <MenuItem key={matter.id} value={matter.id}>
                <Box>
                  <Typography variant="h1" sx={{ fontSize: '1.25rem' }}>
                    {matter.name}
                  </Typography>
                  <Stack direction="row" gap="20px" alignItems="center">
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: '1rem',
                        color: '#404040',
                      }}
                      variant="body2"
                    >
                      {matter.type}
                    </Typography>
                  </Stack>
                </Box>
              </MenuItem>
            ))}
        </Select>
      </Stack>
    </Box>
  );
};

export default MatterTopBar;
