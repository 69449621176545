import { Stack } from '@mui/material';
import React from 'react';
import FinchLogo from '../base/FinchLogo';
import MatterTopBar from '../Topbar';
import MobileIntakeForm from './MobileIntakeForm';
import MobileKeyConsiderations from './MobileKeyConsiderations';

const MobileCms: React.FC = () => {
  // NOTE! This is a VERY temp component for use for Viraj at a conference. Actual mobile work will be done
  // differently.

  return (
    <Stack
      direction="column"
      gap="24px"
      sx={{
        m: '16px',
        display: 'flex',
        alignItems: 'flex-start',
        maxWidth: '380px',
      }}
    >
      <FinchLogo />
      <Stack direction="column" gap="24px">
        <>
          <MatterTopBar />
          <MobileIntakeForm />
          <MobileKeyConsiderations />
        </>
      </Stack>
    </Stack>
  );
};

export default MobileCms;
