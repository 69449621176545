import { Box, ThemeProvider } from '@mui/material';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Cms from './components/pages/Cms';
import LoginForm from './components/pages/LoginForm';
import SignUpForm from './components/pages/SignUpForm';
import PrivateRoute from './components/routing/PrivateRoute';
import SessionManager from './components/routing/SessionManager';
import { store } from './state/state';
import theme from './theme/theme';
import { Call } from './components/pages/Call';

const App: React.FC = () => {
  return (
    <Box className="App" sx={{ display: 'flex', backgroundColor: '#FAFAFB' }}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <SessionManager />
          <Router>
            <Routes>
              <Route path="/signup" element={<SignUpForm />} />
              <Route path="/login" element={<LoginForm />} />
              <Route
                path="/home"
                element={<PrivateRoute element={<Cms />} />}
              />
              <Route
                path="/call/:id"
                element={<PrivateRoute element={<Call />} />}
              />
              <Route path="*" element={<PrivateRoute element={<Cms />} />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </Provider>
    </Box>
  );
};

export default App;
