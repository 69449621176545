import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type UserState = {
  email: string | null;
  loggedIn: boolean;
  csrfToken: string | null;
  sessionId: string | null;
};

const initialState: UserState = {
  email: null,
  loggedIn: false,
  csrfToken: null,
  sessionId: null,
};

export const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string | null>) => {
      state.email = action.payload;
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    setCsrfToken: (state, action: PayloadAction<string | null>) => {
      state.csrfToken = action.payload;
    },
    setSessionId: (state, action: PayloadAction<string | null>) => {
      state.sessionId = action.payload;
    },
  },
});

export const { setEmail, setLoggedIn, setCsrfToken, setSessionId } =
  rootSlice.actions;

export default rootSlice.reducer;
