import {
  Box,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLoginUserMutation } from '../../services/api/userService';
import { setEmail, setLoggedIn } from '../../state/reducers/root';
import { selectLoggedIn } from '../../state/selectors/root-selectors';
import { SecondaryButton } from '../base/Buttons';
import FinchLogoLarge from '../base/FinchLogoLarge';

interface LoginFormState {
  email: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const [formState, setFormState] = useState<LoginFormState>({
    email: '',
    password: '',
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginUser] = useLoginUserMutation();

  const isLoggedIn = useSelector(selectLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home');
    }
  }, [isLoggedIn, navigate]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formState.email)) {
      setError('Please enter a valid email address');
      return;
    }

    setLoading(true);
    try {
      const response = await loginUser({
        username: formState.email,
        password: formState.password,
      }).unwrap();

      dispatch(setEmail(response.user.email));
      dispatch(setLoggedIn(true));
      navigate('/home');
    } catch (e) {
      Sentry.captureException(e);
      // TODO refine this error and pass it to the user if it is specfic from backend
      setError('Invalid email or password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange =
    (prop: keyof LoginFormState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormState({ ...formState, [prop]: event.target.value });
    };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <FinchLogoLarge />
        {error && (
          <Typography
            variant="body1"
            sx={{ mt: '36px', color: 'red', width: '100%' }}
          >
            {error}
          </Typography>
        )}
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: error ? '0px' : '48px' }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={formState.email}
            onChange={handleChange('email')}
            error={!!error && error.includes('email')}
            inputProps={{
              pattern: '[^\\s@]+@[^\\s@]+\\.[^\\s@]+',
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formState.password}
            onChange={handleChange('password')}
            inputProps={{
              minLength: 8,
            }}
          />
          <SecondaryButton
            type="submit"
            fullWidth
            disableRipple
            variant="contained"
            sx={{ mt: 4, mb: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Log In'}
          </SecondaryButton>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginForm;
