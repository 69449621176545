import React from 'react';

const FinchLogo: React.FC = () => {
  return (
    <svg
      width="108"
      height="26"
      viewBox="0 0 108 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="67.625" cy="23.125" r="1.125" fill="white" />
      <path
        d="M95.5344 22V2.80005H98.3664V10.792H98.4144C99.4224 9.04005 100.982 8.27205 102.686 8.27205C105.11 8.27205 107.414 9.83205 107.414 13.6V22H104.558V14.848C104.558 12.64 103.742 10.96 101.702 10.96C99.7824 10.96 98.3664 12.472 98.3664 14.944V22H95.5344Z"
        fill="black"
      />
      <path
        d="M86.8613 22.408C82.9253 22.408 79.9973 19.672 79.9973 15.352C79.9973 11.032 82.9013 8.27203 86.7893 8.27203C89.5013 8.27203 91.9013 9.66403 92.8853 12.328L90.1973 13.408C89.6213 11.752 88.3013 10.96 86.7653 10.96C84.4613 10.96 82.9733 12.856 82.9733 15.328C82.9733 18.016 84.6773 19.72 86.8853 19.72C88.6133 19.72 89.9573 18.688 90.6293 17.008L93.2693 18.04C92.3093 20.8 89.8853 22.408 86.8613 22.408Z"
        fill="black"
      />
      <path
        d="M65.6047 22V8.65603H68.3887V10.792H68.4367C69.4447 9.04003 71.0527 8.27203 72.7567 8.27203C75.1807 8.27203 77.4847 9.83203 77.4847 13.6V22H74.6287V14.848C74.6287 12.64 73.8127 10.96 71.7727 10.96C69.8527 10.96 68.4367 12.448 68.4367 14.944V22H65.6047Z"
        fill="black"
      />
      <path
        d="M59.1829 22V8.65602H62.0149V22H59.1829ZM58.7509 4.33602C58.7509 3.35202 59.5429 2.51202 60.6229 2.51202C61.6549 2.51202 62.4469 3.35202 62.4469 4.33602C62.4469 5.39202 61.6549 6.23203 60.6229 6.23203C59.5429 6.23203 58.7509 5.39202 58.7509 4.33602Z"
        fill="black"
      />
      <path
        d="M44.636 22V3.28003H56.54V6.11203H47.66C47.66 7.84003 47.66 9.59203 47.66 11.344H56.06V14.248H47.66C47.66 16.84 47.66 19.408 47.66 22H44.636Z"
        fill="black"
      />
      <path
        d="M18.5243 5.66763C18.0982 5.07104 17.2459 4.98581 16.7345 5.49718L0.839563 21.2217C0.328197 21.733 0.413425 22.5853 0.967405 22.9688C3.60946 24.8439 6.93334 25.6109 10.2998 25.0569C11.8339 24.8012 13.2828 24.2473 14.6038 23.4376C21.0385 19.5597 22.3595 11.3353 18.5243 5.66763Z"
        fill="url(#paint0_linear_416_2285)"
      />
      <path
        d="M32.5016 6.73298C31.9051 6.13639 31.9051 5.96594 31.8624 5.79548C31.3937 2.4716 28.4959 0 25.1295 0C23.3823 0 21.763 0.639208 20.4845 1.83239L19.547 2.7699C19.1635 3.15342 19.1209 3.75002 19.4618 4.17615C23.4675 9.46027 23.2971 16.9603 18.9078 22.0313C24.9164 22.2018 29.9022 17.3438 29.9022 11.3779C29.9022 10.3978 29.7744 9.41765 29.5187 8.48015C29.4335 8.22447 29.5187 7.96878 29.6465 7.75572C29.817 7.54265 30.03 7.41481 30.3283 7.41481H32.246C32.5443 7.32958 32.7147 6.94605 32.5016 6.73298Z"
        fill="url(#paint1_linear_416_2285)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_416_2285"
          x1="18"
          y1="5"
          x2="0.500001"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#12D0AE" />
          <stop offset="1" stopColor="#03C5A2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_416_2285"
          x1="25.7523"
          y1="0"
          x2="21.5001"
          y2="20.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3E21F3" />
          <stop offset="1" stopColor="#2815A3" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FinchLogo;
