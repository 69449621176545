import {
  Box,
  Grid,
  Input,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  useGetMatterDetailsQuery,
  useUpdateFormFieldMutation,
} from '../../services/api/mattersService';
import {
  selectSelectedMatter,
  selectSelectedMatterData,
} from '../../state/selectors/matter-selectors';
import theme from '../../theme/theme';

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography variant="body2">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

// NOTE! This is a VERY temp component for use for Viraj at a conference. Actual mobile work will be done
// differently.
const MobileIntakeForm: React.FC = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const selectedMatterData = useSelector(selectSelectedMatterData);
  const intakeForm = selectedMatterData?.form;
  const selectedMatterId = useSelector(selectSelectedMatter);
  const [value, setValue] = React.useState(0);
  const [updateFormField] = useUpdateFormFieldMutation();
  const [key, setKey] = React.useState(0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: detailedMatter, refetch } = useGetMatterDetailsQuery(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    selectedMatterId!,
    {
      skip: !selectedMatterId,
    },
  );

  React.useEffect(() => {
    setValue(0);
    if (selectedMatterId) {
      refetch();
    }

    // Force remount of all formfields
    setKey((prevKey) => prevKey + 1);
  }, [detailedMatter, refetch, selectedMatterId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleFormFieldChange = async (
    matterId: string,
    formFieldId: string,
    newValue: string,
  ) => {
    try {
      const patch = {
        answer_data: newValue,
      };
      await updateFormField({
        matter_id: matterId,
        id: formFieldId,
        patch,
      }).unwrap();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  if (!intakeForm) {
    return null;
  }

  return (
    <Paper sx={{ padding: isMobile ? '16px' : '24px', gap: '32px' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">Intake form</Typography>
      </Stack>

      <Box key={key} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="intake form tabs"
          textColor="secondary"
          indicatorColor="secondary"
          orientation={isMobile ? 'vertical' : 'horizontal'}
          variant="scrollable"
          scrollButtons="auto"
        >
          {intakeForm.form_pages?.map((page, index) => (
            <Tab
              disableRipple
              key={index}
              label={page.title}
              id={`tab-${index}`}
              sx={{
                fontFamily: 'Inter',
                textTransform: 'none',
                fontSize: isMobile ? '0.875rem' : '1rem',
                '&.Mui-selected': {
                  color: 'info.main',
                  borderColor: 'info.main',
                },
              }}
            />
          ))}
        </Tabs>
      </Box>
      {intakeForm.form_pages?.map((page, pageIndex) => (
        <TabPanel value={value} index={pageIndex} key={pageIndex}>
          <Grid
            container
            spacing={3}
            columns={{ xs: 12, sm: 12, md: 12 }}
            sx={{ mt: isMobile ? '16px' : '32px' }}
          >
            {[...page.form_fields]
              .sort((a, b) => a.order - b.order)
              .map((field, itemIndex) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  key={itemIndex}
                  sx={{ mt: isMobile ? '16px' : '32px' }}
                >
                  <Stack direction="column" spacing={isMobile ? '8px' : '4px'}>
                    <Typography
                      variant="h2"
                      sx={{
                        fontSize: isMobile ? '16px' : '12px',
                        color: 'text.secondary',
                      }}
                    >
                      {field.title}
                    </Typography>
                    <Input
                      multiline
                      key={key}
                      defaultValue={field.answer_data || 'No data'}
                      onBlur={(e) => {
                        handleFormFieldChange(
                          selectedMatterData.id,
                          field.id,
                          e.target.value,
                        );
                      }}
                      sx={{
                        ...theme.typography.body1,
                        ml: '8px',
                        fontWeight: '500',
                        '&.MuiInput-root::before': {
                          border: 'none',
                        },
                      }}
                    />
                  </Stack>
                </Grid>
              ))}
          </Grid>
        </TabPanel>
      ))}
    </Paper>
  );
};

export default MobileIntakeForm;
