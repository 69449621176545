import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { FlagRounded } from '@mui/icons-material';
import { selectSelectedMatterData } from '../state/selectors/matter-selectors';
import ClientScreenTile from './base/ClientScreenTile';
import { getConsiderationCategoryColor } from '../utils/color';
import {
  MatterKeyConsideration,
  MatterKeyConsiderationCategory,
} from '../services/types/client-intake-types';

const MandatoryFlags: MatterKeyConsiderationCategory[] = [
  MatterKeyConsiderationCategory.CLEAR_LIABILITY,
  MatterKeyConsiderationCategory.SIGNIFICANT_INJURIES,
  MatterKeyConsiderationCategory.INSURANCE_COVERAGE,
];

const KeyConsiderationItem: React.FC<{
  consideration: MatterKeyConsideration;
}> = ({ consideration }) => {
  return (
    <Grid item xs={12} sm={4} key={consideration.id}>
      <Stack direction="row" spacing={1}>
        <FlagRounded
          sx={{
            color: getConsiderationCategoryColor(consideration.severity),
          }}
        />
        <Typography
          variant="body1"
          sx={{
            mb: '16px',
            color: getConsiderationCategoryColor(consideration.severity),
          }}
        >
          {consideration.category}
        </Typography>
      </Stack>
      <Typography variant="subtitle1">{consideration.content}</Typography>
    </Grid>
  );
};

const KeyConsiderations: React.FC = () => {
  const matterData = useSelector(selectSelectedMatterData);

  // Create a dictionary directly from key_considerations
  const keyConsiderations: Record<
    MatterKeyConsiderationCategory,
    MatterKeyConsideration
  > = (matterData?.key_considerations || []).reduce(
    (acc, consideration) => {
      acc[consideration.category] = consideration;
      return acc;
    },
    {} as Record<MatterKeyConsiderationCategory, MatterKeyConsideration>,
  );

  const mandatoryConsiderations: MatterKeyConsideration[] = MandatoryFlags.map(
    (category) => {
      const consideration = keyConsiderations[category];

      if (consideration) {
        return consideration;
      }
      return {
        id: category,
        category,
        severity: 'Gray',
        matter: '',
        content: `No significant observations for "${category}"`,
      };
    },
  );

  const nonMandatoryConsiderations = Object.values(keyConsiderations).filter(
    (consideration) => !MandatoryFlags.includes(consideration.category),
  );

  return (
    <ClientScreenTile>
      <Stack direction="column" spacing={2}>
        <Typography variant="h3">Key Considerations</Typography>
        <Grid container spacing={3} sx={{ maxWidth: '100%' }}>
          {mandatoryConsiderations &&
            mandatoryConsiderations.map((consideration) => (
              <KeyConsiderationItem
                key={consideration.id}
                consideration={consideration}
              />
            ))}
          {nonMandatoryConsiderations &&
            nonMandatoryConsiderations.map((consideration) => (
              <KeyConsiderationItem
                key={consideration.id}
                consideration={consideration}
              />
            ))}
        </Grid>
      </Stack>
    </ClientScreenTile>
  );
};

export default KeyConsiderations;
