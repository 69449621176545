import { RootState } from '../state';

const selectEmail = (state: RootState) => state.root.email;

const selectLoggedIn = (state: RootState) => state.root.loggedIn;

const selectCsrfToken = (state: RootState) => state.root.csrfToken;

const selectSessionId = (state: RootState) => state.root.sessionId;

export { selectCsrfToken, selectEmail, selectLoggedIn, selectSessionId };
