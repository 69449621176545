import { Box, Stack, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCsrfToken } from '../../state/reducers/root';
import {
  selectMatters,
  selectSelectedMatter,
  selectSelectedMatterData,
} from '../../state/selectors/matter-selectors';
import theme from '../../theme/theme';
import KeyConsiderations from '../KeyConsiderations';
import MatterTitle from '../MatterTitle';
import Sidebar from '../Sidebar';
import IntakeCallSection from '../intake-form/IntakeCallSection';
import IntakeForm from '../intake-form/IntakeFormBase';
import MobileCms from '../mobile-pages/MobileCms';
import WelcomeScreen from './WelcomeScreen';
import Summaries from '../Summaries';

const Cms: React.FC = () => {
  const allMatters = useSelector(selectMatters);
  const selectedMatterId = useSelector(selectSelectedMatter);
  const selectedMatterData = useSelector(selectSelectedMatterData);
  const hasExistingMatters = allMatters && allMatters.length > 0;
  const hasKeyConsiderations =
    selectedMatterData?.key_considerations &&
    selectedMatterData?.key_considerations?.length > 0;
  const hasSummaries =
    selectedMatterData?.summaries && selectedMatterData?.summaries?.length > 0;
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const mainContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const csrftoken = document.cookie
      .split('; ')
      .find((row) => row.startsWith('csrftoken='))
      ?.split('=')[1];

    if (csrftoken) {
      dispatch(setCsrfToken(csrftoken));
    }
  }, [dispatch]);

  // Scroll to top when selectedMatterId changes
  useEffect(() => {
    if (mainContentRef.current) {
      mainContentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [selectedMatterId]);

  if (isMobile) {
    return <MobileCms />;
  }

  return (
    <Stack direction="row" sx={{ height: '100vh' }}>
      <Box sx={{ flexShrink: 1 }}>
        <Sidebar />
      </Box>

      {hasExistingMatters ? (
        <Stack
          ref={mainContentRef}
          direction="column"
          gap="24px"
          sx={{
            p: '48px',
            flexGrow: 1,
            overflow: 'auto',
          }}
        >
          <MatterTitle />
          {hasSummaries && <Summaries />}
          {hasKeyConsiderations && <KeyConsiderations />}
          <IntakeCallSection />
          <IntakeForm />
        </Stack>
      ) : (
        <WelcomeScreen />
      )}
    </Stack>
  );
};

export default Cms;
