import { RootState } from '../state';

const selectSelectedMatter = (state: RootState) => state.matters.selectedMatter;

const selectSelectedMatterData = (state: RootState) =>
  state.matters.selectedMatterData;

const selectMatters = (state: RootState) => state.matters.matters;

export { selectMatters, selectSelectedMatter, selectSelectedMatterData };
